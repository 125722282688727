import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import classes from './Table.module.scss'

const ActionCase = ({
	line,
	duplicate,
	remove
}) => {
	return <td className={classes.actionCase}>
		<Tooltip title="Supprimer la ligne">
			<IconButton className={classes.remove} onClick={() => remove(line)}>
				<DeleteForeverIcon style={{ color: 'darkred' }} />
			</IconButton>
		</Tooltip>
		<Tooltip title="Dupliquer la ligne">
			<IconButton className={classes.duplicate} onClick={() => duplicate(line)}>
				<ArrowUpwardIcon style={{ color: 'green' }} />
			</IconButton>
		</Tooltip>
	</td>
};

export default ActionCase;